//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
//import { formatJsonDataset as fjd } from "../../components/renderers";

//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const authconfig = {

  audit: {
    service:{
      name:'audit',
      title:'Audit',
      key:'rid',
      num: false,
      fields: ['rid','nam', 'shc', 'dsc', 'ati','atn', 'eni', 'enm', 'eti', 'aky', 'bii', 'bin', 'bic', 'cre', 'lgo', 'exi', 'sts', 'ast', 'stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['atc','Type'],['enm','Entity'],['cre','Credits'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'ati',label:'Account Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'accounttype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'cre',label:'Credit',type:'nf',req:0,ops:{}},
            {id:'lgo',label:'Logo',type:'ff',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },
  
  context: {
    service: {
      name: 'context',
      title: 'Context',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'aky', 'acl', 'sts', 'ast', 'stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',aky:'Access Key',acl:'Access Level',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['aky','Access Key'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'aky',label:'Access Key',type:'mf',req:1,ops:{params:{s:'context',a:'access'},conf:{recField:'rid'}}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  entity: {
    service: {
      name: 'entity',
      title: 'Entity',
      key: 'rid',
      num: true,
      fields: ['rid','nam','eti','ety','adr','loc','ct1','ct2','fax','eml','web','com','ila','pla','sts','ast','stp'],
      params: {nam:'Name',eti:'EntityTypeId',ety:'EntityType',adr:'Address',sts:'Status'},
      grid: [['nam','Name'],['ety','Type'],['loc','Location'],['ct1','Contact'],['eml','Email'],['web','Web'],
             ['pla','PLA'],['ila','ILA'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Contact',v:'ct1'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',col:1,req:1,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entitytype'}},
            {id:'adr',label:'Address',type:'ta',col:1,req:0,ops:{}},
            {id:'loc',label:'Location',type:'tf',col:1,req:0,ops:{}},
            {id:'ct1',label:'Contact',type:'tf',col:1,req:0,ops:{}},
            {id:'ct2',label:'Phone',type:'tf',col:1,req:0,ops:{type:'tel'}},
            {id:'fax',label:'Fax',type:'tf',col:2,req:0,ops:{}},
            {id:'eml',label:'Email',type:'tf',col:2,req:0,ops:{type:'email'}},
            {id:'web',label:'web',type:'tf',col:2,req:0,ops:{type:'url'}},
            {id:'com',label:'Comments',type:'ta',col:2,req:0,ops:{}},
            {id:'ila',label:'ILA',type:'nf',col:2,req:0,ops:{type:'range',min:0,max:10},off:['add','edit']},
            {id:'pla',label:'PLA',type:'nf',col:2,req:0,ops:{type:'range',min:0,max:10},off:['add','edit']},
            {id:'sts',label:'Status',type:'fa',col:2,req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column'}
  },

  entitytype: {
    service: {
      name: 'entitytype',
      title: 'Entity Type',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'pls', 'sts', 'ast', 'stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',pls:'Privileges',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['pls','Privileges'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'pls',label:'Privileges',type:'ta',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  error: {
    service: {
      name: 'error',
      title: 'Error',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'msg', 'sts', 'ast', 'stp'],
      params: {nam:'Error',shc:'Code',dsc:'Description',acl:'Message',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['msg','Message'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'msg',label:'Message',type:'tf',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  loglevel: {
    service: {
      name: 'loglevel',
      title: 'Log Level',
      key: 'rid',
      num: true,
      fields: ['rid','stn','sty','sti','dsc','sts','ast','stp'],
      params: {nam:'Setting',shc:'Code',typ:'Type',val:'Value',dtl:'Details',sts:'Status'},
      grid: [['stn','Name'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Setting',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'stn',label:'Name',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sty',label:'Status Type',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sti',label:'Status Type ID',type:'rf',req:1,ops:{},off:['edit']},
            {id:'dsc',label:'Description',type:'rf',req:1,ops:{},off:['edit']},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  privilege: {
    service:{
      name:'privilege',
      title:'Privilege',
      key:'rid',
      num: false,
      fields: ['rid','nam','shc','mnm','smn','mng','lnm','ord','acl','btx','bfn','acf','ctx','sts','ast','stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Privilege'],['shc','Code'],['mnm','Menu'],['smn','Submenu'],['ord','Order'],['acl','Access'],
             ['acf','Module'],['bfn','Action'],['btx','Label'],['ctx','Context'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Privilege',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,col:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:1,col:1,ops:{}},
            {id:'mnm',label:'Menu',type:'tf',req:1,col:1,ops:{}},
            {id:'mng',label:'Group',type:'tf',req:1,col:1,ops:{}},
            {id:'smn',label:'Submenu',type:'tf',req:1,col:1,ops:{}},
            {id:'lnm',label:'Language',type:'tf',req:1,col:1,ops:{}},
            {id:'ord',label:'Order',type:'tf',req:1,col:2,ops:{}},
            {id:'acl',label:'Access',type:'ml',req:1,col:2,ops:{params:{s:'role',a:'find'},conf:{keyField:'aky'}}},
            {id:'btx',label:'Label',type:'tf',req:1,col:2,ops:{}},
            {id:'bfn',label:'Action',type:'tf',req:1,col:2,ops:{}},
            {id:'acf',label:'Module',type:'tf',req:1,col:2,ops:{}},
            {id:'ctx',label:'Context',type:'ml',req:1,col:2,ops:{params:{s:'context',a:'find'},conf:{keyField:'aky'}}},//,cp:{t:'bcs',s:'context'}
            {id:'sts',label:'Status',type:'fa',req:1,col:2,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column'}
  },

  profile: {
    service: {
      name: 'profile',
      title: 'Profile',
      key: 'rid',
      num: true,
      fields: [
        'rid','nam','fnm','onm','snm','unm','roi','rnm','eti','ety','eni','enm','rfi','ct1','ct2','eml',
        'lvi','lvn','mct','sto','pla','ila','lst','flg','dct','lpd','lld','rmk','sts','ast','stp'],
      params: {nam:'Status Name',sty:'Type',sti:'Status Id',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['unm','Username'],['ety','EntityType'],['rnm','Role'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'fnm',label:'Firstname',type:'tf',req:1,ops:{}},
            {id:'onm',label:'Othername',type:'rf',req:1,ops:{}},
            {id:'snm',label:'Surname',type:'rf',req:1,ops:{}},
            {id:'nam',label:'Username',type:'rf',req:1,ops:{}},
            {id:'unm',label:'Username',type:'rf',req:1,ops:{}},
            {id:'rnm',label:'Role',type:'rf',req:1,ops:{}},
            {id:'ety',label:'Entity Type',type:'rf',req:1,ops:{}},
            {id:'enm',label:'Entity',type:'rf',req:1,ops:{}},
            {id:'ct1',label:'Mobile',type:'rf',req:1,ops:{}},
            {id:'ct2',label:'Code',type:'rf',req:1,ops:{}},
            {id:'eml',label:'Email',type:'rf',req:1,ops:{}},
            {id:'lvn',label:'Log Level',type:'rf',req:1,ops:{}},
            {id:'mct',label:'Messages',type:'rf',req:1,ops:{}},
            {id:'sto',label:'Session Timeout',type:'rf',req:1,ops:{}},
            {id:'pla',label:'Plain Login',type:'rf',req:1,ops:{}},
            {id:'ila',label:'Image Login',type:'rf',req:1,ops:{}},
            {id:'lst',label:'Login Status',type:'rf',req:1,ops:{}},
            {id:'flg',label:'Failed Login',type:'rf',req:1,ops:{}},
            {id:'dct',label:'Created',type:'rf',req:1,ops:{}},
            {id:'lpd',label:'Last Password',type:'rf',req:1,ops:{}},
            {id:'lld',label:'Last Login',type:'rf',req:1,ops:{}},
            {id:'rmk',label:'Remarks',type:'rf',req:1,ops:{}},
            {id:'ast',label:'Status',type:'rf',req:1,ops:{}},
            {id:'stp',label:'Last Updated',type:'rf',req:1,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  role: {
    service:{
      name:'role',
      title:'Role',
      key:'rid',
      num: true,
      fields: ['rid','nam', 'dsc', 'sto','aky', 'acl', 'eti', 'ety', 'sts', 'ast', 'stp'],
      params: {nam:'Service',dsc:'Description',sto:'Timeout',sts:'Status'},
      grid: [['nam','Name'],['dsc','Description'],['sto','Session Timeout'],['aky','Access Key'],['acl','Access Level'],['ety','EntityType'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Description',v:'dsc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entitytype'}},
            {id:'aky',label:'Access Key',type:'mf',req:0,ops:{params:{s:'role',a:'access'},conf:{recField:'rid'}}},
            {id:'sto',label:'Timeout',type:'nf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  setting: {
    service: {
      name: 'setting',
      title: 'Setting',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','typ','val','dtl','sts','ast','stp'],
      params: {nam:'Setting',shc:'Code',typ:'Type',val:'Value',dtl:'Details',sts:'Status'},
      grid: [['nam','Setting'],['shc','Code'],['typ','Type'],['val','Value'],['dtl','Details'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Setting',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Setting',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
            {id:'typ',label:'Type',type:'tf',req:1,ops:{}},
            {id:'val',label:'Value',type:'tf',req:1,ops:{}},
            {id:'dtl',label:'Detail',type:'ta',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  status: {
    service: {
      name: 'status',
      title: 'Status',
      key: 'rid',
      num: true,
      fields: ['rid','stn','sty','sti','dsc','sts','ast','stp'],
      params: {nam:'Status Name',sty:'Type',sti:'Status Id',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['sty','Type'],['sti','Status ID'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'sty',label:'Type',type:'tf',req:1,ops:{}},
            {id:'sti',label:'Status ID',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  user: {
    service:{
      name:'user',
      title:'User',
      fields:['rid','fnm','onm','snm','nam','unm','roi','rnm','eti','ety','eni','enm',
              'pla','ila','flg','ct1','ct2','eml','sid','sto','lst','dct','lld','lpd',
              'mct','bri','brn','bcd','tli','tla','lvi','lvn','rmk','sts','ast','stp'],
      grid: [['nam','Name'],['rnm','Role'],['ety','EntityType'],['enm','Entity'],['ct1','Mobile'],
             ['eml','Email'],['lvn','Loglevel'],['lst','Login'],['ast','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Mobile',v:'mbn'}],def:'nam'},
      eform:[{id:'fnm',label:'Firstname',type:'tf',col:1,req:1,ops:{}},
            {id:'onm',label:'Othernames',type:'tf',col:1,req:0,ops:{}},
            {id:'snm',label:'Surname',type:'tf',col:1,req:1,ops:{}},
            {id:'unm',label:'Username',type:'tf',col:1,req:1,ops:{}},
            {id:'eti',label:'Entity Type',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entitytype'},chs:['eni']},
            {id:'eni',label:'Entity',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'entity'},pns:['eti']},
            {id:'roi',label:'Role',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'role'}},
            {id:'rfi',label:'Branch',type:'fa',col:1,req:1,ops:{},cp:{t:'bcs',s:'branch'}},
            {id:'sts',label:'Status',type:'fa',col:1,req:1,ops:{},cp:{t:'lcs',s:'status'}},
            {id:'ct1',label:'Mobile',type:'tf',col:2,req:1,ops:{type:'tel'}},
            {id:'ct2',label:'Contact',type:'tf',col:2,req:0,ops:{}},
            {id:'eml',label:'Email',type:'tf',col:2,req:1,ops:{type:'email'}},
            {id:'pla',label:'Login Attempts',type:'nf',col:2,req:0,ops:{},off:['add']},
            {id:'sto',label:'Session Timeout',type:'nf',col:2,req:0,ops:{}},
            {id:'flg',label:'False Login',type:'nf',col:2,req:0,ops:{}},
            {id:'lst',label:'Login Status',type:'fa',col:2,req:0,ops:{},cp:{t:'bcs',s:'loginstatus'}},
            {id:'lvi',label:'Log Level',type:'fa',col:2,req:0,ops:{},cp:{t:'bcs',s:'loglevel'}},
            {id:'com',label:'Comments',type:'ta',col:2,req:0,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View',reset:'simple'},
    panels: {add:'column',edit:'column',delete:'dialog',view:'column',close:'dialog'}
  }
};

//export {authconfig};
