//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
//import { formatJsonDataset as fjd } from "../../components/renderers";
import {authconfig} from './authconfig';
import {coreconfig} from './coreconfig';
import {contentconfig} from './contentconfig';
import {platformconfig} from './platformconfig';
import {uploadconfig} from './uploadconfig';

//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';



export const serviceconfig = {...authconfig,...coreconfig,...contentconfig,...platformconfig,...uploadconfig};
