import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
// import List, {
//   ListSubheader, 
//   ListItem, 
//   ListItemAvatar, 
//   ListItemText, 
//   ListItemSecondaryAction 
// } from '@mui/material/List';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import DeleteIcon from '@mui/icons-material/Delete';
import {cyan} from '@mui/material/colors';
import Wallpaper from '@mui/icons-material/Wallpaper';
import moment from 'moment';
import {bizpost} from '../../../ajax';
import Preview from './Preview';

const styles = (theme:Theme):any => ({
  paper: {
    backgroundColor: 'white',
    minHeight: 400,
    //overflow: 'scroll',
  },
  preview: {
    //height: 95,
    padding: '5px 15px 0 15px'
  },
  header: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: cyan['600'],
    padding: 10,
  },
  nodata: {
    height:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  // subheader: {
  //   fontSize: 24,
  //   fontWeight: theme.typography.fontWeightLight,
  //   //backgroundColor: cyan['600'],
  //   color: white
  // }
});

class Recent extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0,
      recid:0,
      record:{rst:'PENDING',info:[{title:'Date',value:'2020-02-01'},{title:'Type',value:'1PHASE'}]},
      preview:false,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  formatDate = (stamp:any) => {
    return moment(stamp,'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD-MMM HH:mm');
  }

  formatMoney = (currency:any,money:any) => {
    //.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    const cur = (currency)?`${currency} `:'';
    return (!!+money)?`${cur}${(+money).toFixed(2)}`:money;
  }

  preview = (rid:any) => async(ev:any) => {
    try{
      const baseparams = {s:'register',a:'detail',rid};
      const response = await bizpost(baseparams);
      console.log('reg resp',response);
      const {sd} = response;
      const record = sd[0];
      this.setState({record,preview:true,recid:rid});
    }
    catch(error){
      console.log('preview error',error);
    } 
    //this.setState({preview:true,recid:rid});
  }

  closePreview = () => {
    this.setState({preview:false});
  }

  render() {
    const {data,title='Recent Records',classes} = this.props;
    const {preview,recid,record} = this.state;
    //rid,dcd,sno,ssn,man,mmc,mac,mcc,bno,msc
    //{rid: 2, txd: '2024-08-31T21:50:38.044868', cmc: 'testing', ttc: 'COMMISSION', amt: 100}
    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>{title}</div>
        {data.length>0?
        <div className={classes.preview}>
        <Preview open={preview} recid={recid} record={record} onClose={this.closePreview} />
        <List>
          {data.map((item:any,idx:number) =>
            <div key={item.rid}>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar>
                    <Wallpaper />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  // primary={`${item.ent} ${this.formatMoney(item.amt)}`} 
                  //primary={`${item.sno} ${item.ssn} No:${item.idx} Box:${~~(item.idx/8)+1} BoxNo:${(item.idx%8)}`} 
                  //secondary={`${this.formatDate(item.dcd)}, ${item.mmc}, ${item.mcc}, ${item.bno}, ${item.msc}`}
                  //rid,dcd,pds as dsc,xtc,amt
                  //rid,tri,dcd,rfn,enm,enc,csi,acc,evc,vsc
                  primary={`${item.enc}... ${item.evc}, ${item.vsc}, ${item.acc}`} 
                  secondary={`${this.formatDate(item.dcd)}, ${item.rfn}, ${item.tri}`}
                  />
                <ListItemSecondaryAction>
                  <IconButton aria-label="View" onClick={this.preview(item.rid)}>
                    <MoreVertIcon color="action" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              {(idx<data.length-1)&&<Divider variant="inset" />}
            </div>
          )}
        </List>
        </div>
        : <div className={classes.nodata}>No data to show</div>}
      </Paper>
    );
  }
}

Recent.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Recent);
