//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
import { formatJsonLocation as fjl } from "../../components/renderers";
import { formatJsonDataset as fjd } from "../../components/renderers";

//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const platformconfig = {
  
  activity: {
    service: {
      name: 'activity',
      title: 'Activity',
      key: 'rid',
      num: true,
      fields:['rid','nam','shc','dsc','ati','atn','atc','eni','enm','enc','ord','exi','sts','ast','stp'],
      params: {nam:'Name',shc:'Code',dsc:'Description',ati:'Type',eni:'Entity',sts:'Status'},
      grid: [['nam','Activity'],['shc','Shortcode'],['dsc','Description'],['atc','Type'],['enc','Entity'],['ord','Order'],['exi','Extra Info'],['ast','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
             {id:'shc',label:'Shortcode',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'ati',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'activitytype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'ord',label:'Order',type:'nf',req:0,ops:{}},
            {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
            //{id:'dtl',label:'Details',type:'rf',req:0,ops:{}},
            {id:'sts',label:'Record Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
            //{id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  compliance: {
    //fieldTypes: {tf:'',ta:'',nf:'',df:'',cf:'',rf:'',cb:'',fa:'',rb:'',if:'',ff:''},
    service:{
      name:'compliance',
      title:'Compliance',
      key:'rid',
      num: false,
      fields:['rid','aci','acn','acc','evi','evn','evc','rqm','cri','thr','exi','sts','ast','stp'],
      grid  : [['acc','Activity'],['evc','Event'],['rqm','Requirement'],['cri','Criteria'],['thr','Thresholds'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      //			paramsadd:':rfn,:tdt,:aai,:xti,:tci,:csi,:dsc,:xsi,:rst,:exi,:dtl',

      eform:[
        //{id:'dcd',label:'Date',type:'rf',req:0,ops:{}},
        //{id:'tri',label:'Track ID',type:'rf',req:0,ops:{}},
        //{id:'rfn',label:'Request',type:'rf',req:0,ops:{}},
        //{id:'csn',label:'Customer',type:'rf',req:0,ops:{}},
        //{id:'idn',label:'Message',type:'rf',req:0,ops:{}},
        //{id:'enm',label:'Entity',type:'rf',req:0,ops:{}},
        {id:'acn',label:'Activity',type:'rf',req:0,ops:{}},
        //{id:'rtn',label:'Request',type:'rf',req:0,ops:{}},
        //{id:'rsn',label:'Request State',type:'rf',req:0,ops:{}},
        {id:'evn',label:'Event',type:'rf',req:0,ops:{}},
        {id:'rqm',label:'Requirement',type:'rf',req:0,ops:{}},
        {id:'cri',label:'Criteria',type:'rf',req:0,ops:{}},
        {id:'thr',label:'Threshold',type:'rf',req:0,ops:{}},
        {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
        {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
        {id:'stp',label:'Stamp',type:'rf',req:0,ops:{}}
      ],
    },
    create:false,
    //actions: {add:'Add',view:'View'},
    //panels: {add:'simple',view:'simple'}
    actions: {add:'Add',edit:'Edit',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  customer: {
    service:{
      name:'customer',
      title:'Customer',
      fields:['rid','nam','cti','ctn','ctc','iti','itn','itc','idn','mbn','eml','dsc','exi','sts','ast','stp'],
      grid: [['nam','Name'],['ctc','Type'],['itc','IDType'],['idn','IDNumber'],['mbn','Mobile'],['eml','Email'],['dsc','Description'],['exi','ExtraInfo'],
             ['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Customer',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'rf',req:1,ops:{}},
            {id:'cti',label:'Type',type:'rf',req:1,ops:{},cp:{t:'bcs',s:'customertype'}},
            {id:'iti',label:'ID Type',type:'fa',req:0,ops:{},cp:{t:'bcs',s:'idtype'}},
            {id:'idn',label:'ID No',type:'rf',req:0,ops:{} },
            {id:'mbn',label:'Mobile',type:'rf',req:0,ops:{type:'tel'}},
            {id:'eml',label:'Email',type:'rf',req:0,ops:{type:'email'}},
            {id:'dsc',label:'Description',type:'rf',req:0,ops:{}},
            {id:'exi',label:'Extra Info',type:'rf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
      // eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
      //       {id:'cti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'customertype'}},
      //       {id:'iti',label:'ID Type',type:'fa',req:0,ops:{},cp:{t:'bcs',s:'idtype'}},
      //       {id:'idn',label:'ID No',type:'tf',req:0,ops:{} },
      //       {id:'mbn',label:'Mobile',type:'tf',req:0,ops:{type:'tel'}},
      //       {id:'eml',label:'Email',type:'tf',req:0,ops:{type:'email'}},
      //       {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
      //       {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
      //       {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
      //     ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {
      add:'simple',edit:'simple',delete:'dialog',view:'simple',close:'dialog'
    }
  },

  device: {
    service: {
      name: 'device',
      title: 'Device',
      key: 'rid',
      num: true,
      fields: ['rid','dvn','dvk','dvc','dvm','dsc','dti','dtn','dtc','eni','enm','enc','lci','lcn','lcc','geo','dcd','exi','sts','ast','stp'],
      params: {dvn:'Name',dvk:'Devicekey',dvc:'Devicecode',dvm:'Devicemask',dsc:'Description',dti:'Type',eni:'Entity',lci:'Location',dcd:'Created',geo:'Geocode',exi:'Extrainfo',sts:'Status'},
      grid: [['dvn','Name'],['dvk','Key'],['dvc','Code'],['dvm','Mask'],['dsc','Description'],['dtc','Type'],['enc','Entity'],['lcn','Location'],['geo','Geocode'],['dcd','Created'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'dvn'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'dvn',label:'Name',type:'tf',req:1,ops:{}},
            {id:'dvk',label:'Key',type:'tf',req:1,ops:{}},
            {id:'dvc',label:'Code',type:'tf',req:1,ops:{}},
            {id:'dvm',label:'Mask',type:'nf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'dti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'devicetype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location'}},
            {id:'geo',label:'Geocode',type:'gl',req:0,ops:{},off:['add','edit']},
            {id:'dcd',label:'Created',type:'rf',req:0,ops:{}},
            {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {geo:fjl,exi:fjd}}
  },


  event: {
    service:{
      name:'event',
      title:'Events',
      num: true,
      fields:['rid','nam','shc','dsc','vti','vtn','vtc','ord','exi','sts','ast','stp'],
      params: {nam:'Name',shc:'Code',dsc:'Description',vti:'Type',sts:'Status'},
      grid: [['nam','Activity'],['shc','Shortcode'],['dsc','Description'],['vtc','Type'],['ord','Order'],['exi','Extra Info'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
             {id:'shc',label:'Shortcode',type:'tf',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'vti',label:'Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'eventtype'}},
            {id:'ord',label:'Order',type:'nf',req:0,ops:{}},
            {id:'exi',label:'Extra Info',type:'jf',req:0,ops:{}},
            //{id:'dtl',label:'Details',type:'rf',req:0,ops:{}},
            {id:'sts',label:'Record Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
            //{id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  // event: {
  //   service:{
  //     name:'event',
  //     title:'Events',
  //     num: true,
  //     fields:['rid','dbi','dcd','act','ent','fld','rec','uid','nam','cmi','chn','mbn','unm','cha','ami','amc','usi','sts','ast','stp'],
  //     grid  : [['dcd','Date'],['usi','Auditee'],['amc','Mode'],['act','Action'],['nam','User'],['cmi','Customer'],['chn','Channel'],['mbn','Mobile']],
  //     menu: {mnu:[{t:'Auditee',v:'usi'}],def:'usi'},
  //     eform:[
  //           {id:'dbi',label:'DB ID',type:'rf',col:1,req:0,ops:{}},
  //           {id:'dcd',label:'Date Created',type:'rf',col:1,req:0,ops:{}},
  //           {id:'amc',label:'Audit Mode',type:'rf',col:1,req:0,ops:{}},
  //           {id:'act',label:'Action',type:'rf',col:1,req:0,ops:{}},
  //           {id:'ent',label:'Module',type:'rf',col:1,req:0,ops:{}},
  //           {id:'fld',label:'Field',type:'rf',col:1,req:0,ops:{}},
  //           {id:'uid',label:'MUser ID',type:'rf',col:2,req:0,ops:{}},
  //           {id:'nam',label:'MUser Name',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cmi',label:'CustomerID',type:'rf',col:2,req:0,ops:{}},
  //           {id:'chn',label:'Channel',type:'rf',col:2,req:0,ops:{}},
  //           {id:'mbn',label:'Mobile',type:'rf',col:2,req:0,ops:{}},
  //           {id:'cha',label:'Changes',type:'jf',col:2,req:0,ops:{}},
  //           {id:'usi',label:'Auditee',type:'rf',col:1,req:0,ops:{}},
  //           {id:'ast',label:'Status',type:'rf',col:1,req:0,ops:{}},
  //           {id:'stp',label:'Stamp',type:'rf',col:1,req:0,ops:{}}
  //     ],
  //   },
  //   create:false,
  //   panels: { 
  //     view:'column',close:'dialog',check:'dialog',print:'result'
  //   },
  //   //formatters: {grid: {req:fjd,res:fjd,exi:fjd}}
  // },

  logger: {
    service:{
      name:'logger',
      title:'Event Logs',
      num: true,
      fields:['rid','tri','vno','dvi','dvn','dvc','eni','enc','loi','geo','dcd','exi','sts','ast','stp'],
      grid  : [['tri','TrackId'],['vno','Version'],['dvn','Device'],['enc','Entity'],['geo','Geocode'],['dcd','Created'],['exi','Extrainfo'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[
            {id:'tri',label:'Tracking Id',type:'rf',req:0,ops:{}},
            {id:'vno',label:'Version',type:'rf',req:0,ops:{}},
            {id:'dvn',label:'Device',type:'rf',req:0,ops:{}},
            {id:'enc',label:'Entity',type:'rf',req:0,ops:{}},
            {id:'geo',label:'Geocode',type:'rf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'rf',req:0,ops:{}},
            {id:'dcd',label:'Created',type:'rf',req:0,ops:{}},
            {id:'exi',label:'Extrainfo',type:'rf',req:0,ops:{}},
            {id:'stp',label:'Stamp',type:'rf',req:0,ops:{}}
      ],
    },
    create:false,
    panels: { 
      view:'column',close:'dialog',check:'dialog',print:'result'
    },
    formatters: {grid: {req:fjd,res:fjd,exi:fjd}}
  },

  notification: {
    service:{
      name:'notification',
      title:'Event Notification',
      num: true,
      fields:['rid','tri','sen','rcp','msg','nti','ntc','nsi','nsc','rqi','rfn','rvi','rvd','dsc','dcd','exi','usi','sts','ast','stp'],
      grid  : [['tri','TrackId'],['sen','Sender'],['rcp','Recipient'],['msg','Message'],['ntc','Type'],['nsc','State'],['rfn','Reference'],
              ['rvd','Reviewed'],['dsc','Description'],['dcd','Created'],['exi','Extra'],['sts','status'],['stp','Stamp']],
      menu: {mnu:[{t:'Auditee',v:'usi'}],def:'usi'},
      eform:[
            {id:'tri',label:'Track ID',type:'rf',req:0,ops:{}},
            {id:'sen',label:'Sender',type:'rf',req:0,ops:{}},
            {id:'rcp',label:'Recipient',type:'rf',req:0,ops:{}},
            {id:'msg',label:'Message',type:'rf',req:0,ops:{}},
            {id:'ntc',label:'Type',type:'rf',req:0,ops:{}},
            {id:'nsc',label:'State',type:'rf',req:0,ops:{}},
            {id:'rfn',label:'Request',type:'rf',req:0,ops:{}},
            {id:'rvd',label:'Review',type:'rf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'rf',req:0,ops:{}},
            {id:'dcd',label:'Created',type:'rf',req:0,ops:{}},
            {id:'exi',label:'Extrainfo',type:'rf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'rf',req:0,ops:{}},
            {id:'stp',label:'Stamp',type:'rf',req:0,ops:{}}
      ],
    },
    create:false,
    panels: { 
      view:'column',close:'dialog',check:'dialog',print:'result'
    },
    //formatters: {grid: {req:fjd,res:fjd,exi:fjd}}
  },

//   activity
// customer
// device
// event
//logger
//notification
//register
//request
//review

  register: {
    //fieldTypes: {tf:'',ta:'',nf:'',df:'',cf:'',rf:'',cb:'',fa:'',rb:'',if:'',ff:''},
    service:{
      name:'register',
      title:'Register',
      key:'rid',
      num: false,
      fields:['rid','tri','rqi','rfn','csi','csn','idn','eni','enm','enc',
      'aci','acn','acc','rti','rtn','rtc','rsi','rsn','rsc','evi',
      'evn','evc','vti','vtn','vtc','vno','vsi','vsn','vsc','dsc','dcd',
      'exi','nts','sts','ast','stp'],
      grid  : [['dcd','Date'],['rfn','Reference'],['tri','TrackID'],['vno','Version'],['csn','Customer'],['idn','ID'],['enc','Entity'],
              ['acc','Activity'],['rsc','Req State'],['evc','Event'],['vtc','Type'],['vsc','State'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      //			paramsadd:':rfn,:tdt,:aai,:xti,:tci,:csi,:dsc,:xsi,:rst,:exi,:dtl',

      eform:[
        {id:'dcd',label:'Date',type:'rf',req:0,ops:{}},
        {id:'tri',label:'Track ID',type:'rf',req:0,ops:{}},
        {id:'rfn',label:'Request',type:'rf',req:0,ops:{}},
        {id:'csn',label:'Customer',type:'rf',req:0,ops:{}},
        //{id:'idn',label:'Message',type:'rf',req:0,ops:{}},
        {id:'enm',label:'Entity',type:'rf',req:0,ops:{}},
        {id:'acn',label:'Activity',type:'rf',req:0,ops:{}},
        //{id:'rtn',label:'Request',type:'rf',req:0,ops:{}},
        {id:'rsn',label:'Request State',type:'rf',req:0,ops:{}},
        {id:'evn',label:'Event',type:'rf',req:0,ops:{}},
        {id:'vtn',label:'Type',type:'rf',req:0,ops:{}},
        {id:'vsn',label:'State',type:'rf',req:0,ops:{}},
        {id:'dsc',label:'Description',type:'rf',req:0,ops:{}},
        {id:'exi',label:'Extrainfo',type:'rf',req:0,ops:{}},
        {id:'sts',label:'Status',type:'rf',req:0,ops:{}},
        {id:'stp',label:'Stamp',type:'rf',req:0,ops:{}}
      ],
    },
    create:false,
    //actions: {add:'Add',view:'View'},
    //panels: {add:'simple',view:'simple'}
    actions: {add:'Add',edit:'Edit',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  request: {
    service:{
      name:'request',
      title:'Request',
      num: true,
      fields:['rid','rfn','csi','csn','idn','eni','enm','enc',
        'aci','acn','acc','rti','rtn','rtc','rsi','rsn','rsc','dsc','dcd',
        'exi','nts','sts','ast','stp'],
        grid  : [['dcd','Date'],['rfn','Reference'],['tri','TrackID'],['vno','Version'],['csn','Customer'],['idn','ID'],['enc','Entity'],
                ['acc','Activity'],['rtc','Type'],['rsc','State'],['dsc','Description'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
        bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
        menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
        //			paramsadd:':rfn,:tdt,:aai,:xti,:tci,:csi,:dsc,:xsi,:rst,:exi,:dtl',
  
        eform:[
          {id:'dcd',label:'Date',type:'rf',req:0,ops:{}},
          //{id:'tri',label:'Track ID',type:'rf',req:0,ops:{}},
          {id:'rfn',label:'Request',type:'rf',req:0,ops:{}},
          {id:'csn',label:'Customer',type:'rf',req:0,ops:{}},
          //{id:'idn',label:'Message',type:'rf',req:0,ops:{}},
          {id:'enm',label:'Entity',type:'rf',req:0,ops:{}},
          {id:'acn',label:'Activity',type:'rf',req:0,ops:{}},
          {id:'rtn',label:'Type',type:'rf',req:0,ops:{}},
          {id:'rsn',label:'State',type:'rf',req:0,ops:{}},
          //{id:'evn',label:'Event',type:'rf',req:0,ops:{}},
          //{id:'vtn',label:'Type',type:'rf',req:0,ops:{}},
          //{id:'vsn',label:'State',type:'rf',req:0,ops:{}},
          {id:'dsc',label:'Description',type:'rf',req:0,ops:{}},
          {id:'exi',label:'Extrainfo',type:'rf',req:0,ops:{}},
          {id:'sts',label:'Status',type:'rf',req:0,ops:{}},
          {id:'stp',label:'Stamp',type:'rf',req:0,ops:{}}
        ],
    },
    create:false,
    panels: { 
      //edit:'simple',view:'result',close:'dialog',check:'dialog',print:'result'
      view:'simple',close:'dialog',check:'dialog',print:'result'
    },
    formatters: {
      grid: {rbo:fjd,rin:fjd,exi:fjd}
    }
  },

  review: {
    service:{
      name:'review',
      title:'Review',
      num: true,
      fields:['rid','rqi','dcd','cli','cln','clc','rto','rbo','rin','cco','chn',
              'rsi','rsn','rsc','aui','aun','auc','rco','rtx','exi','sts','ast','stp'],
      grid  : [['dcd','Date'],['rqi','RequestID'],['cln','Client'],['rto','Token'],['rbo','Body'],['rin','Info'],['cco','Code'],
               ['chn','Channel'],['rsc','State'],['aun','Auth'],['rtx','Response'],['exi','Extra'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Date',v:'sdt'},{t:'Code',v:'cco'}],def:'cco'},
      eform:[
        {id:'aci',label:'Status Code',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'requeststatus'},off:['edit']},
        {id:'sco',label:'Status Code',type:'tf',req:1,ops:{} },
        {id:'stx',label:'Status Text',type:'ta',req:0,ops:{} },
        {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},     
      ],
    },
    create:false,
    panels: { 
      //edit:'simple',view:'result',close:'dialog',check:'dialog',print:'result'
      view:'result',close:'dialog',check:'dialog',print:'result'
    },
    formatters: {
      grid: {rbo:fjd,rin:fjd,exi:fjd}
    }
  },

}
