import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import {pink,purple} from '@mui/material/colors';
//import {Tooltip, BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid} from 'recharts';
import { ResponsiveContainer} from 'recharts';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';
import { LatLngExpression } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


const styles = (theme:Theme):any => ({
  maproot: {
    flexGrow: 1,
    flexShrink: 1,
  },
  mapboardpaper: {
    backgroundColor: 'white',
    //maxHeight: 600,
    height: '100%',
    // height: 150
    marginLeft: 10,
    marginRight: 10,
  },
  mapdiv: {
    // height: 95,
    //padding: '5px 15px 0 15px'
  },
  mapheader: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: purple['600'],
    padding: 10,
  },
  paper: {
    backgroundColor: 'white',
    minHeight: 400,
    // overFlow: 'hidden'
    height: '100%',
    marginTop: 10,
  },
  div: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '95%',
    //height: 300,
  },
  header: {
    color: 'white',
    backgroundColor: pink['600'],
    padding: 10,
    fontSize: 24,
    fontWeight: 300,
  },
  custom_tooltip:{
    lineHeight: 0.2,
    backgroundColor: 'white',
    padding: 5
  },
  nodata: {
    display: 'flex',
    justifyContent: 'center'
  },
  barchart:{
    marginTop: 20
  }
});

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

class EventMap extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    const mcenter:LatLngExpression = [8.977850, 39.253099]; //[7.5823, -1.9470];
    const mpos:LatLngExpression = [9.01055, 38.74750]; //[6.647991, -0.579113];
    const mtext = `NTRMS. <br /> Event Location.`;
    const mbounds:LatLngBoundsExpression = [[4.71046214438, -3.24437008301],[11.0983409693, 1.0601216976]];
    const mzoom = 7;
    
    const {mapcenter=mcenter,mapbounds=mbounds,mapzoom=mzoom,markerpos=mpos,markertext=mtext} = props;

    this.state = {
      count: 0,
      mode: 'total', //amount
      mapcenter,
      mapbounds,
      mapzoom,
      markerpos,
      markertext,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  // convert = (data:any,mode:any) => {
  //   var names = ['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  //   var rtn = data.map((item:any) =>(
  //     {name:names[item.dmonth],year:item.dyear,value:item[mode]}
  //   ));
  //   //console.log('month',rtn);
  //   return rtn;
  // }

  // customTooltip = ({active, payload, label}: any) => {
  //   const {classes} = this.props;
  //   if(payload === null || payload === undefined) return null;
    
  //   if(active){
  //     return(
  //       <div className={classes.custom_tooltip}>
  //         <p>{label}</p>
  //         <p className={classes.revenue}>{`${payload[0].name} : ${'GHS '+payload[0].value}`}</p>
  //       </div>
  //     )
  //   }
  //   return null;
  // }

  render() {
    const {data,title='Monthly Transactions',classes} = this.props;
    const {mode} = this.state;
    //const chartdata = this.convert(data,mode);
    //  width={730} height={250}
    const {mapcenter,mapbounds,mapzoom,markerpos,markertext,} = this.state;
    const {recordset,mcenter=mapcenter,mbounds=mapbounds,mzoom=mapzoom,mpos=markerpos,mtext=markertext} = this.props;
    return (
      <Paper className={classes.paper}>
        <div className={classes.header}>{title}</div>
        <div className={classes.div}>
          <ResponsiveContainer >
            <MapContainer center={mcenter} zoom={mzoom} scrollWheelZoom={false} style={{height:'80vh',width:'100%'}} bounds={mbounds}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" bounds={mbounds}
              />
              <Marker position={mpos}>
                <Popup>
                  {mtext}
                </Popup>
              </Marker>
              {recordset.map((record:any, idx:number) => 
                <Marker key={record.rid} position={record.pos}>
                <Popup>
                  <span>{record.txt}</span>
                </Popup>
              </Marker>
              )}
            </MapContainer>
          </ResponsiveContainer>
        </div>
      </Paper>

        // <Paper className={classes.mapboardpaper}>
        // <div className={classes.mapheader}>{title}</div>
        // <div className={classes.mapdiv}>
        //   <ResponsiveContainer >
        //     <MapContainer center={mapcenter} zoom={7} scrollWheelZoom={false} style={{height:'80vh',width:'100%'}} bounds={bounds}>
        //       <TileLayer
        //         attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" bounds={bounds}
        //       />
        //       <Marker position={apexpos}>
        //         <Popup>
        //           {apextext}
        //         </Popup>
        //       </Marker>
        //       {recordset.map((record:any, idx:number) => 
        //         <Marker key={record.rid} position={record.pos}>
        //         <Popup>
        //           <span>{record.txt}</span>
        //         </Popup>
        //       </Marker>
        //       )}
        //     </MapContainer>
        //   </ResponsiveContainer>
        // </div>
        // </Paper>

      // <Paper className={classes.paper}>
      //   <div className={classes.header}>{title}</div>
      //   <div className={classes.div}>
      //     <ResponsiveContainer >
      //       {data.length>0?
      //       <BarChart className={classes.barchart} data={chartdata} >
      //         <CartesianGrid strokeDasharray="3 3" />
      //         <XAxis dataKey="name"/>
      //         <YAxis dataKey="value" />
      //         {/* <Tooltip /> */}
      //         <Tooltip content={<this.customTooltip />} />
      //         <Bar dataKey="value" name="Meters" fill={pink['500']}/>
      //       </BarChart>
      //       : <div className={classes.nodata}>No data to show</div>}
      //     </ResponsiveContainer>
      //   </div>
      // </Paper>
    );
  }
}

EventMap.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(EventMap);
