
const basicconfig = {
  
  //////////////////////////
  //    AUTH
  //////////////////////////

  branchtype: {
    service:{
      name:'branchtype',
      title:'Branch Type',
    }
  },

  // gender: {
  //   service:{
  //     name:'gender',
  //     title:'Gender',
  //   }
  // },

  version: {
    service:{
      name:'version',
      title:'App Version',
    }
  },
  //////////////////////////
  //    CORE
  //////////////////////////

  country: {
    service:{
      name:'country',
      title:'Country',
    }
  },

  currency: {
    service:{
      name:'currency',
      title:'Currency',
    }
  },

  dashtype: {
    service:{
      name:'dashtype',
      title:'Dash Type',
    }
  },

  gender: {
    service:{
      name:'gender',
      title:'Gender',
    }
  },

  idtype: {
    service:{
      name:'idtype',
      title:'ID Type',
    }
  },

  region: {
    service:{
      name:'region',
      title:'Region',
    }
  },

  report: {
    service:{
      name:'report',
      title:'Reports',
    }
  },

  //////////////////////////
  //    PLATFORM 
  //////////////////////////

  activitystate: {
    service:{
      name:'activitystate',
      title:'Activity State',
    }
  },

  activitytype: {
    service:{
      name:'activitytype',
      title:'Activity Type',
    }
  },

  customertype: {
    service:{
      name:'customertype',
      title:'Customer Type',
    }
  },

  devicetype: {
    service:{
      name:'devicetype',
      title:'Device Type',
    }
  },

  eventstate: {
    service:{
      name:'eventstate',
      title:'Event State',
    }
  },

  eventtype: {
    service:{
      name:'eventtype',
      title:'Event Type',
    }
  },

  notificationstate: {
    service:{
      name:'notificationstate',
      title:'Notification State',
    }
  },

  notificationtype: {
    service:{
      name:'notificationtype',
      title:'Notification Type',
    }
  },

  requeststate: {
    service:{
      name:'requeststate',
      title:'Request State',
    }
  },

  requesttype: {
    service:{
      name:'requesttype',
      title:'Request Type',
    }
  },

  reviewstate: {
    service:{
      name:'reviewstate',
      title:'Review State',
    }
  },

  reviewtype: {
    service:{
      name:'reviewtype',
      title:'Review Type',
    }
  },

  //////////////////////////
  //    API
  //////////////////////////


  //////////////////////////
  //    AGENCY
  //////////////////////////

  // agency: {
  //   service:{
  //     name:'agency',
  //     title:'Agency',
  //   }
  // },

  
  //////////////////////////
  //    UPLOAD
  //////////////////////////

  filegroup: {
    service:{
      name:'filegroup',
      title:'File Group',
    }
  },

  paramgroup: {
    service:{
      name:'paramgroup',
      title:'Parameter Group',
    }
  },

  paramstatus: {
    service:{
      name:'paramstatus',
      title:'Parameter Status',
    }
  },

  //////////////////////////
  //    CONTENT
  //////////////////////////

  uidevice: {
    service:{
      name:'uidevice',
      title:'UI Device',
    }
  },

  uidoc: {
    service:{
      name:'uidoc',
      title:'UI Document',
    }
  },

  uilang: {
    service:{
      name:'uilang',
      title:'UI Language',
    }
  },

  uipage: {
    service:{
      name:'uipage',
      title:'UI Page',
    }
  },

  uisection: {
    service:{
      name:'uisection',
      title:'UI Section',
    }
  },

};

export {basicconfig};
