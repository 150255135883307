//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
import { formatJsonLocation as fjl } from "../../components/renderers";
import { formatJsonDataset as fjd } from "../../components/renderers";

//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const coreconfig = {
  
  configuration: {
    service: {
      name: 'configuration',
      title: 'Configuration',
      key: 'rid',
      num: true,
      fields: ['rid','nam', 'shc', 'dsc', 'val', 'sts', 'ast', 'stp'],
      params: {nam:'Name',shc:'Code',dsc:'Description',val:'Value',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['val','Value'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'val',label:'Value',type:'tf',req:1,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  connection: {
    service:{
      name:'connection',
      title:'Connection',
      num: true,
      fields:['rid','nam','shc','dsc','val','sts','ast','stp'],
      grid:[['nam','Name'],['shc','Code'],['val','Value'],['dsc','Description'],['sts','Status'],['stp','Stamp']],
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
        {id:'shc',label:'Code',type:'tf',req:1,ops:{}},
        {id:'val',label:'Value',type:'tf',req:1,ops:{}},
        {id:'dsc',label:'Description',type:'tf',req:0,ops:{}},
        {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},]
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  dashmeta: {
    service:{
      name:'dashmeta',
      title:'Dashmeta',
      fields:['rid','nam','shc','dsc','dti','dtn','dtc','ttl','dgp','dfm','dva','dun',
              'col','pal','ico','exi','sts','ast','stp'],
      grid: [['nam','Name '],['shc','Code'],['dtn','Dashtype Name'],['ttl','Tittle'],['dgp','Data Group'],
             ['dfm','Data Format'],['dva','Data Value'],['exi','Extra Info'],['sts','Status'],['stp','Stamp']],
             //':nam,:shc,:dsc,:dti,:dtn,:dtc,:ttl,:dgp,:dfm,:dva,:dun,:col,:pal,:ico,:exi,:sts'
      menu: {mnu:[{t:'Customer',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,col:1,ops:{}},
            {id:'shc',label:'Shortcode',type:'tf',req:1,col:1,ops:{}},
            {id:'dti',label:'Dash Type ID',type:'fa',req:1,col:1,ops:{},cp:{t:'bcs',s:'dashtype'}},
            {id:'dtn',label:'Dash Type Name',type:'tf',req:1,col:1,ops:{}},
            {id:'dtc',label:'Dash Type Code',type:'tf',req:0,col:1,ops:{}},
            {id:'ttl',label:'Tittle',type:'tf',req:1,col:1,ops:{}},
            {id:'dgp',label:'Data Group',type:'tf',req:0,col:1,ops:{}},
            {id:'dfm',label:'Data Format',type:'tf',req:0,col:1,ops:{} },
            {id:'dva',label:'Data Value',type:'tf',req:0,col:2,ops:{}},
            {id:'dun',label:'Data Unit',type:'tf',req:0,col:2,ops:{}},
            {id:'col',label:'Color',type:'tf',req:0,col:2,ops:{}},
            {id:'pal',label:'Palette',type:'tf',req:0,col:2,ops:{}},
            {id:'ico',label:'Icon',type:'tf',req:0,col:2,ops:{}},
            {id:'dsc',label:'Description',type:'tf',req:0,col:2,ops:{}},
            {id:'exi',label:'Extra Info',type:'jf',req:0,col:2,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,col:2,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {
      add:'column',edit:'column',delete:'dialog',view:'column',man:'tab',close:'dialog'
    },
    formatters: {grid: {exi:fjd}}
  },

  district: {
    service: {
      name: 'district',
      title: 'District',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rgi','rgn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rgi:'Region',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rgn','Region'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rgi',label:'Region',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'region'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  location: {
    service: {
      name: 'location',
      title: 'Location',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','dsi','dsn','rgi','rgn','geo','exi','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',dsi:'District',rgi:'Region',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['dsn','District'],['rgn','Region'],['geo','Geolocation'],['exi','ExtraInfo'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'dsi',label:'District',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'district'}},
            {id:'geo',label:'Geolocation',type:'gl',req:1,ops:{}},
            {id:'exi',label:'ExtraInfo',type:'jf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {geo:fjl,exi:fjd}}
  },

  reportheader: {
    service: {
      name: 'reportheader',
      title: 'Report Header',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  reportsettings: {
    service: {
      name: 'reportsettings',
      title: 'Report Settings',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  reportsummary: {
    service: {
      name: 'reportsummary',
      title: 'Report Summary',
      key: 'rid',
      num: true,
      fields: ['rid','nam','shc','dsc','rpi','rpn','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',rpi:'Report',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['rpn','Report'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'rpi',label:'Report',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'report'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

}
