//import React from 'react';
//import {render} from 'react-dom';
//import * as ReactDOMClient from 'react-dom/client';
import {createRoot} from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//import Reboot from '@material/material/core/Reboot';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import {purple,green,grey,pink,yellow} from '@material/material/core/colors';
import LoginPage from './components/startup/LoginPage';
import HomePage from './components/startup/HomePage';
import {bizpost} from './ajax';
import "./index.css";

const gold = {light:'#decc95',main:'#ffc107',dark:'#664e04',contrastText: '#000000'};
const lemon = {light:'#c1e312',main:'#b5e312',dark:'#bdde14',contrastText: '#ffffff'};
const temp = {light:'',main:'',dark:'',contrastText: '#ffffff'};

const theme = createTheme({
  palette: {
    primary: {
      light: '#00AEEF',
      main: '#1DACD6',
      dark: '#24419B',
      contrastText: '#ffffff',
    },
    secondary: {...lemon},
    // secondary: {
    //   //m:#ffc107,l:#decc95,d:#664e04
    //   light: '#c1e312', //'#843e9c', //'#c3DA4B', //'#ffdb99',
    //   main: '#b5e312', //'#540d6d', //'#e1a96a',//'#BDDA57',
    //   dark: '#bdde14', //'#280041', //'#C3DA4B', //'#ad7a3d',
    //   contrastText: "#ffffff", //'#000000',
    // },
    text: { hint: 'rgba(0, 0, 0, 0.38)' },
  },

  // palette: {
  //   mode: 'light',
  //   primary: {
  //     main: '#100f4d',
  //   },
  //   secondary: {
  //     main: '#eb0c0d',
  //     contrastText: '#f4f4f4',
  //   },
  // },
  
});

const start = async()=>{
  const logincontext = false;
  try{
    const json = {s:'security',a:'basicdata'};
    //startLoading();
    const response = await bizpost(json);
    console.log('login response ',response);
    var page;
    if(!response.bd){
      page = (<LoginPage home={HomePage} apptheme={theme} logincontext={logincontext} />);
    }
    else{
      page = <HomePage  udata={response} />;
    }
  }
  catch(error){
    page = <LoginPage home={HomePage} apptheme={theme} logincontext={logincontext} />;
  }
  finally{
    //stopLoading();
    const container = document.getElementById('app');

    // Create a root.
    if(container !== null){
      const root = createRoot(container);
      root.render(<ThemeProvider theme={theme}>{page}</ThemeProvider>);
    }
    
    //render(<MuiThemeProvider theme={theme}>{page}</MuiThemeProvider>, document.getElementById('app'));
  }
}

start();


// // Before
// import { render } from 'react-dom';
// const container = document.getElementById('app');
// render(<App tab="home" />, container);

// // After
// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);