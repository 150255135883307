//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
import { formatJsonDataset as fjd } from "../../components/renderers";

const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const uploadconfig = {
  
  file: {
    service: {
      name: 'file',
      title: 'Upload File',
      key: 'rid',
      num: true,
      fields: ['rid','pgn','pgc','sts','ast','stp'],
      grid: [['pgn','Group'],['pgc','Code'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Group',v:'pgn'},{t:'Code',v:'pgc'}],def:'pgn' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramactive: {
    service: {
      name: 'paramactive',
      title: 'Active Parameter',
      key: 'rid',
      num: true,
      fields: ['rid','pgn','pgc','sts','ast','stp'],
      grid: [['pgn','Group'],['pgc','Code'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Group',v:'pgn'},{t:'Code',v:'pgc'}],def:'pgn' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramhistory: {
    service: {
      name: 'paramhistory',
      title: 'Parameter Setting History',
      key: 'rid',
      num: true,
      fields: ['rid','tki','mei','sno','ssn','pgi','pgc','par','att','dvi','dvc','sti','stc','psi','psc','err','nts','dcd','rmk','exi','sts','ast','stp'],
      grid: [['tki','Trackid'],['sno','Meter'],['ssn','STS No'],['pgc','Group'],['dvc','Device'],['stc','Station'],['psc','ParamStatus'],['dcd','Created'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view'] },
      menu: {mnu:[{t:'Trackid',v:'tki'},{t:'Meter',v:'sno'},{t:'STSNo',v:'ssn'}],def:'sno' },//*
      form: ['aci','svi','sts'],
      eform: [
        { id:'nam',label:'Name',type:'tf',req:1,ops:{} },
        { id:'shc',label:'Code',type:'tf',req:1,ops:{} },
        { id:'scd',label:'Sort Code',type:'tf',req:1,ops:{} },
        { id:'swc',label:'Swift Code',type:'tf',req:1,ops:{} },
        { id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'} },
        { id:'lci',label:'Location',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'location' } },
        { id:'exi',label:'Extra Info',type:'jf',req:0,ops:{} },
        { id:'dtl',label:'Details',type:'ta',req:0,ops:{} },
        { id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status' } }
      ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {exi:fjd}}
  },

  paramsetting: {
    service:{
      name:'paramsetting',
      title:'Parameter Setting',
      key:'rid',
      num: false,
      fields: ['rid','nam', 'shc', 'dsc', 'ati','atn', 'eni', 'enm', 'eti', 'aky', 'bii', 'bin', 'bic', 'cre', 'lgo', 'exi', 'sts', 'ast', 'stp'],
      params: {nam:'Service',shc:'Code',dsc:'Description',sts:'Status'},
      grid: [['nam','Name'],['shc','Code'],['dsc','Description'],['atc','Type'],['enm','Entity'],['cre','Credits'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
      eform:[{id:'nam',label:'Name',type:'tf',req:1,ops:{}},
            {id:'shc',label:'Code',type:'tf',req:0,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'ati',label:'Account Type',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'accounttype'}},
            {id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'cre',label:'Credit',type:'nf',req:0,ops:{}},
            {id:'lgo',label:'Logo',type:'if',req:0,ops:{preview:true}},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  },

  paramtemplate: {
    service:{
      name:'paramtemplate',
      title:'Parameter Template',
      key:'rid',
      num: false,
      fields: ['rid','pgi', 'pgn', 'pgc', 'hds','pms', 'rds', 'att', 'dcd', 'tot', 'fln', 'res', 'exi', 'sts', 'ast', 'stp'],
      params: {pgn:'Group',pgc:'Code',hds:'Headers',pms:'Parameters',rds:'Records',att:'Attachments',dcd:'Created',tot:'Total',fln:'Filename',res:'Resource',exi:'Extrainfo',sts:'Status'},
      grid: [['pgn','Parameter Group'],['pgc','Code'],['dcd','Created'],['pms','Parameters'],['tot','Total'],['fln','Filename'],['exi','ExtraInfo'],['sts','Status'],['stp','Stamp']],
      bar: {pag:true,btn:['add','edit','del','view','man','wiz']},
      menu: {mnu:[{t:'Group',v:'pgn'},{t:'Code',v:'pgc'}],def:'pgn'},
      eform:[{id:'pgi',label:'Param Group',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'paramgroup'}},
            {id:'hds',label:'Headers',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'pms',label:'Parameters',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'rds',label:'Records',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'att',label:'Attachments',type:'jf',req:0,ops:{},off:['add','edit','delete']},
            {id:'dcd',label:'Created',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'tot',label:'Total',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'fln',label:'File',type:'fu',req:0,ops:{preview:false,accept:xlsaccept}},
            {id:'res',label:'Resource',type:'df',req:0,ops:{},off:['add','edit','delete']},
            {id:'exi',label:'Extrainfo',type:'jf',req:0,ops:{}},
            {id:'sts',label:'Status',type:'fa',req:1,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'},
    formatters: {grid: {pms:fjd,res:fjd,exi:fjd}, form:{pms:fjd,res:fjd,exi:fjd}}
  },

}

