//import { formatJsonLocation as fjl, formatLocation } from "../../components/renderers";
//import { formatJsonDataset as fjd } from "../../components/renderers";

//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

export const contentconfig = {

  uicontent: {
    service: {
      name: 'uicontent',
      title: 'UI Content',
      key: 'rid',
      num: true,
      fields: ['rid','eni','enn','enc','rfi','dci','dcn','dcc','pgi','pgn','pgc','sci','scn','scc',
               'dvi','dvn','dvc','lgi','lgn','lgc','msg','dsc','amk','sts','ast','stp'],
      params: {nam:'Context',shc:'Code',dsc:'Description',dba:'Debi Account',cda:'Credit Account',sts:'Status'},
      grid: [['enn','Entity'],['rfi','Refid'],['dcn','Document'],['pgn','Page'],['scn','Section'],['dvn','Device'],
             ['lgn','Language'],['msg','Message'],['dsc','Description'],['amk','Access Mask'],['sts','Status'],['stp','Stamp']],
		  bar: {pag:true,btn:['add','edit','del','view']},
      menu: {mnu:[{t:'Entity',v:'enn'},{t:'Document',v:'dcc'}],def:'enn'},
      eform:[{id:'eni',label:'Entity',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'entity'}},
            {id:'dci',label:'Document',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uidoc'}},
            {id:'pgi',label:'Page',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uipage'}},
            {id:'sci',label:'Section',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uisection'}},
            {id:'dvi',label:'Device',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uidevice'}},
            {id:'lgi',label:'Language',type:'fa',req:1,ops:{},cp:{t:'bcs',s:'uilang'}},
            {id:'msg',label:'Message',type:'ta',req:1,ops:{}},
            {id:'dsc',label:'Description',type:'ta',req:0,ops:{}},
            {id:'amk',label:'Access Mask',type:'nf',req:0,ops:{pattern:'[0-9]+'}},
            {id:'sts',label:'Status',type:'fa',req:0,ops:{},cp:{t:'lcs',s:'status'}},
          ],
    },
    create:true,
    actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View'},
    panels: {add:'simple',edit:'simple',delete:'dialog',view:'simple'}
  }

}

//export {contentconfig};
